import React from "react";
import Cover from "images/icon/arrow-right.svg";
import { Link } from "gatsby";
function introduceBlock({title}) {
  return (
    <div className="home__introduceBlock mg">
      <div className="container py-6">
        <div className="introduceBlock__row">
          <h1 className="hidden-text"></h1>
          <p className="pbt">
            <strong>
              {title.title}
            </strong>
            <span>
              {title.description}
            </span>
          </p>
          <Link className="button" title="Ve-chung-toi" to="/ve-chung-toi">
              Về chúng Tôi
              <img src={Cover} className="icon_button" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default introduceBlock;
