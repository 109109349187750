import React,{useState} from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import ArticlesGrid from "../components/articles-grid";
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Swipperslider from "../components/homepage/swiperslider";
import TargetItem from "../components/homepage/targetItem";
import IntroduceBlock from "../components/homepage/introduceBlock";
import Partner from "../components/homepage/partner";
import BlockProject from "../components/homepage/blockProject";
import RecruitBlock from "../components/homepage/recruitBlock";
import ContactBlock from "../components/homepage/contactBlock";
import BlockIcon from "../components/homepage/block-icon";
import iconangleup from "images/phone-alt.svg";
const IndexPage = ({element}) => {
  const { allStrapiArticle, strapiGlobal, allStrapiRecruit } =
    useStaticQuery(graphql`
      query ($skip: Int! = 0){
        allStrapiArticle(
          sort: { fields: [createdAt], order: DESC }
          limit: 3
          skip: $skip
        ) {
          nodes {
            ...ArticleCard
          }
        }
        allStrapiRecruit (
          sort: { fields: [createdAt], order: DESC }
          limit: 10
          skip: $skip
        ){
          nodes {
            link
            id
            time
            title
            quantity
            description {
              data {
                id
                description
              }
            }
          }
        }
        allStrapiPartner {
          nodes {
            category
            title
            image {
              alternativeText
              localFile {
                id
                url
              }
            }
          }
        }
        strapiGlobal {
          title
          description
          defaultSeo {
            metaDescription
            metaTitle
            metaImage {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          imagePartners {
            partners {
              image {
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80
                      webpOptions: {quality: 80}
                      placeholder: BLURRED)
                  }
                }
              }
            }
          }
          siteName
          siteDescription
          listIcons {
            listIcons {
              image {
                url
                alternativeText
                localFile {
                  url
                  id
                  childImageSharp {
                    gatsbyImageData(quality: 80 webpOptions: {quality: 80} placeholder: BLURRED)
                  }
                }
              }
              title
              description
              id
            }
          }
          mainSlider {
            projects {
              title
              description
              slug
              cover {
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80
                      webpOptions: {quality: 80}
                      placeholder: BLURRED)
                  }
                }
              }
            }     
            projectsSwiper {
              title
              description
              slug
              cover {
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 80
                      webpOptions: {quality: 80}
                      placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    `);
    const [fix, setFix] = useState(false)
    function setFixed () {
      if(window.scrollY >= 500){
        setFix(true)      
      }else{
        setFix(false)
      }
    }
    if (typeof window !== `undefined`){
      window.addEventListener("scroll", setFixed) 
    }
  return (
    <Layout>
      <GatsbySeo 
        title={strapiGlobal.defaultSeo.metaTitle}
      />
      <Swipperslider mainSliderSwiper={strapiGlobal.mainSlider}/>
      <TargetItem listIcons={strapiGlobal.listIcons.listIcons} />
      <IntroduceBlock title={strapiGlobal}/>
      <Partner sliderpartners={strapiGlobal.imagePartners} />
      <BlockProject mainSliders={strapiGlobal.mainSlider} />
      <main className="homenews">
        <ArticlesGrid articles={allStrapiArticle.nodes} />
      </main>
      <RecruitBlock recruit={allStrapiRecruit.nodes} />
      <ContactBlock />
      <BlockIcon/>
    </Layout>
  );
};

export default IndexPage;
