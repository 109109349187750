import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
const ArticleCard = ({ article }) => {
  return (
    <Link
      to={`/tin-tuc/${article.slug}`}
      className="overflow-hidden rounded-lg bg-white shadow-sm transition-shadow hover:shadow-md"
      title={article?.title}
    >
       <div className="hover01 column">
        <figure>  
          <GatsbyImage image={article.image?.localFile.childImageSharp.gatsbyImageData} alt={article.image.alternativeText} className="img_next"/>  
        </figure>
      </div>
      <div className="px-4 py-4">
        <div className="news__desc">
          <strong>{article?.publishedAt}</strong>
        </div>
        <h3 className="font-bold text-neutral-700">{article?.title}</h3>
        <p className="mt-2 text-neutral-500 line-clamp-2">
          {article?.description}
        </p>
      </div>
    </Link>
  );
};

export const query = graphql`
  fragment ArticleCard on STRAPI_ARTICLE {
    id
    slug
    title
    description
    publishedAt
    image {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 80 webpOptions: {quality: 80} placeholder: BLURRED)
        }
      }
    }
  }
`;
export default ArticleCard;
