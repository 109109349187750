import React from "react";
import Cover from "images/icon/arrow-rights.svg";
import Covers from "images/icon/arrow-right.svg";
import { Link } from "gatsby";
import img from "images/bgd-hiring.webp";
const recruitBlock = ({ recruit }) => {
  return (
    <div className="home__recruitmentBlock home__siteTitle">
      <div className="list__recruitmentBlock container ">
        <div className="recruitmentBlock__row title__recruit">
          <h2 className="mgbt pdt">THÔNG TIN TUYỂN DỤNG</h2>
          <ul>
            {recruit.map((item, index) => (
              <Link key={index} title={item.title} to="/tuyen-dung">
                <li>
                  <p>{item.title}</p>
                  <img src={Cover} className="icon_button" />
                </li>
              </Link>
            ))}
          </ul>
          <Link title="tuyen-dung" className="button mgt mgbt" to="/tuyen-dung">
              Tuyển dụng <img src={Covers} className="icon_button" />
          </Link>
        </div>
        <div>
          <img src={img} className="img__projectBlockRG" />
        </div>
      </div>
    </div>
  );
};

export default recruitBlock;
