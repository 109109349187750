import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const partner = ({ sliderpartners }) => {
  return (
    <div className="siteTitle container py-6">
      <div className="home__siteTitle siteTitle__swiper">
        <h2 className="pdt mgbt">ĐỐI TÁC CHIẾN LƯỢC</h2>
        <Swiper
          spaceBetween={50}
          slidesPerView={2}
          loop={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 60,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 100,
            },
          }}
        >
          {sliderpartners?.partners?.map((partnerimg, key) => (
            <SwiperSlide key={key}>
              <div className="sliderrowssimg">
                <GatsbyImage image={partnerimg.image.localFile.childImageSharp.gatsbyImageData} alt={partnerimg.image.alternativeText}  className="partner__imgs"/>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default partner;
