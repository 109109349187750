import React, { useState } from "react";
React.useLayoutEffect = React.useEffect;
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "../../styles/home.css";
import {
  Pagination,
  Navigation,
  Autoplay,
  Controller,
  EffectFade,
} from "swiper";
import "swiper/css/effect-fade";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import Cover from "images/icon/arrow-rights.svg";
const AccordionCustom = ({ accordion }) => {
  const swiper = useSwiper();
  return (
    <>
      <div className="container">
        <div className="box home__siteTitle">
          <h2 className="siteTitle">Dự án đang phân phối</h2>
          <Accordion defaultIndex={[0]} allowToggle className="accordion">
            {accordion.mainSliders?.projects?.map((item, index) => (
              <AccordionItem key={`item-${index}`} className="accordionItem">
                <AccordionButton
                  className="accordionButton"
                  onClick={() => swiper.slideTo(index)}
                >
                  <strong className="accordionTitle">{item.title}</strong>
                  <AccordionIcon className="accordionIcon" />
                </AccordionButton>
                <AccordionPanel className="accordionDesc">
                  {item.description}
                      <Link target="_blank" title={item.title} to={`/du-an/${item.slug}`} className="accordionDesc__a">
                        Chi tiết <img src={Cover}/>
                      </Link>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};

const blockProject = (mainSliders) => {
  const [openedIndex, setOpenedIndex] = useState(0);
  const swiper = useSwiper();
  return (
    <div className="section">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        navigation={false}
        modules={[Navigation, EffectFade, Autoplay, Controller]}
        className="imageSlider"
        controller={{ control: openedIndex }}
        effect="fade"
      >
        <AccordionCustom accordion={mainSliders} />
        {mainSliders.mainSliders?.projects?.map((item, index) => (
          <SwiperSlide key={`slide-${index}`} className="slide">
            <div className="sliderInner">
              <div className="image">
              <GatsbyImage image={item.cover.localFile.childImageSharp.gatsbyImageData} alt={item.cover.alternativeText} className="projectBlock__img"/> 
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default blockProject;
