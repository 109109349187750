import React from "react";
import ArticleCard from "./article-card";
import Cover from "images/icon/arrow-right.svg";
import { Link } from "gatsby";
const ArticlesGrid = ({ articles }) => {
  return (
    <div>
      <div className="home__news home__siteTitle container">
        <h2>Tin tức</h2>
        <div>
          <Link title="tin-tuc" className="button flex" to="/tin-tuc/1">
              Toàn bộ tin tức
              <span>
                <img src={Cover} className="icon_button" />
              </span>
          </Link>
        </div>
      </div>
      <div className="container mt-12 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {articles.map((article , key) => (
          <ArticleCard key={key} article={article} />
        ))}
      </div>
    </div>
  );
};

export default ArticlesGrid;
