import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Pagination,
  Navigation,
  Autoplay,
  Controller,
  EffectFade,
} from "swiper";
import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../styles/home.css";
import Cover from "images/icon/arrow-right.svg";
import { Link } from "gatsby";

const swiperslider = ({ mainSliderSwiper }) => {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  return (
    <div className="mantsilder">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Navigation, EffectFade, Autoplay, Controller]}
        className={`home-slide`}
        effect="fade"
        onSwiper={setFirstSwiper}
        controller={{ control: secondSwiper }}
      >
        {mainSliderSwiper.projectsSwiper.map((slideIMG, index) => (
          <SwiperSlide key={index} id={`heroSlide-${index}`}>
            <div className="sliderrows">
              <GatsbyImage image={slideIMG.cover.localFile.childImageSharp.gatsbyImageData} alt={slideIMG.cover.alternativeText} className="silderImg"/>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper
        slidesPerView={1}
        loop={true}
        onSwiper={setSecondSwiper}
        pagination={{
          clickable: true,
        }}
        controller={{ control: firstSwiper }}
        modules={[EffectFade, Controller, Pagination]}
        className="descSlider"
        effect="fade"
      >
        {mainSliderSwiper.projectsSwiper.map((slidetitle, id) => (
          <SwiperSlide key={id} id={`heroSlide-${id}`}>
            <div className="home__siteTitles">
              <div className="desc home__siteTitle">
                <h2>{slidetitle.title}</h2>
                <div className="desc__slider">{slidetitle.description}</div>
              </div>
            </div>
            <div className="home__sliderbuton">
              <Link className="button" title={slidetitle.title}  to={`/du-an/${slidetitle.slug}`}>
                  Tìm hiểu thêm
                  <img src={Cover} className="icon_button" />
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default swiperslider;
