import React from "react";
import {GatsbyImage } from "gatsby-plugin-image";
const targetItem = ({ listIcons }) => {
  return (
    <div className="container py-6">
      <div className="home__targetItem mgbt">
        <ul className="targetItem__row">
          {listIcons.map((item, key) => (
            <li key={key}>
              <GatsbyImage
                image={item.image.localFile?.childImageSharp?.gatsbyImageData}
                className="targetItem__icon" alt={item.image.alternativeText}
              />
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default targetItem;
