import React,{useEffect,useState } from "react";
import Covers from "images/icon/arrow-right.svg";
import iconclose from "images/icon/times.svg";
import iconlogo from "images/alphaland_logo.svg";
import { Formik, Field, Form} from "formik";
function contactBlock() {
  const [indexFrom, setIndexFrom] = useState("Trang Chủ");
  const [nameFrom, setNameFrom] = useState("");
  const [phoneFrom, setPhoneFrom] = useState("");
  const [emailFrom, setEmailFrom] = useState("");
  const [contentFrom, setContentFrom] = useState("");
  const [popup, setPopup] = useState(false);
  const onClickPupop = () =>{
      if(nameFrom == "" ){
      }else{
          if(phoneFrom == ""){
          }else{ 
          setPopup(true) 
          }
      }
  }
  return (
    <div className="contact__flex container py-6">
      <div className="home__siteTitle home__contact">
        <h2 className="mgbt">LIÊN HỆ VỚI CHÚNG TÔI</h2>
        <strong>
          Để được chúng tôi hỗ trợ tốt nhất, vui lòng cho chúng tôi biết thêm
          thông tin về quý khách!
        </strong>
        {popup &&
                <><div onClick={() =>{setPopup(false)}} className="popup__fix"> </div>
                <div className="popup">
                    <div className="close__img" onClick={() =>{setPopup(false)}}><img src={iconclose} className="icon__close"/></div>
                    <img className="image__logo-close" src={iconlogo}/>
                    <div className="home__siteTitle">
                    <div className="popup__recruitmentBlock">
                    <div className="pupop__row title__recruit">
                        <h2 className="mgbt pdt">Cảm Ơn Quý Khách Đã Để Lại thông tin cá nhân</h2>
                        <div>
                            Chúng Tôi Sẽ Liên Hệ Với Bạn Sớm Nhất !!!
                        </div>
                    </div>
                    </div>
                    </div>
                 </div>
                 </>  
                }
        <Formik
                    initialValues={{nameFrom,phoneFrom,emailFrom,contentFrom}}
                    onSubmit={async (values) => {
                    let data = {
                        'entry.545121623': indexFrom,
                        'entry.678634837': nameFrom,
                        'entry.344178928': phoneFrom,
                        'entry.529291201': emailFrom,
                        'entry.730657229': contentFrom
                    }
                    await new Promise((resolve) => setTimeout(resolve, 500));
                        let queryString = new URLSearchParams(data);
                        queryString = queryString.toString();
                        console.log(queryString)
                        let xhr = new XMLHttpRequest();
                        xhr.open('POST', 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfiLFI4iyP8LE0ePxSULb0mUTg0d4LwDROiKjmZelFj2qUclw/formResponse', true);
                        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                        xhr.send(queryString)
                        setNameFrom("")
                        setPhoneFrom("")
                        setEmailFrom("")
                        setContentFrom("")
                        setPopup(true)
                    }}
                >
                    <Form>
                        <Field className="formControl" name="name" placeholder="Họ và tên" type="text" value={nameFrom} onChange={(e) => setNameFrom(e.target.value)} required/>
                        <Field className="formControl" name="phone" placeholder="Số Điện Thoại"  type="text" value={phoneFrom} onChange={(e) => setPhoneFrom(e.target.value)} required/>
                        <Field className="formControl" name="email" placeholder="Email của bạn"  type="email" value={emailFrom} onChange={(e) => setEmailFrom(e.target.value)} required/>
                        <Field className="formControl" name="content" placeholder="Nội Dung"  type="text" value={contentFrom} onChange={(e) => setContentFrom(e.target.value)} required/>
                        <button type="submit" onClick={(onClickPupop)} className="button">  Gửi Thông tin <img src={Covers} className="icon_button" /></button>
                    </Form>
          </Formik>
      </div>
      <div className="home__chart">
        <div className="contact__phone ">
          <div>
            <div>
              <h3>Trụ sở chính</h3>111 Trương Văn Bang, P. Thạnh Mỹ Lợi, TP. Thủ
              Đức, Hồ Chí Minh
            </div>
          </div>
          <div>
            <div>
              <h3>Thông tin liên hệ</h3><a href="tel:0932657979">0932 657 979</a>{" "}
              <p><a href="mailto:info@alphaland.com.vn">info@alphaland.com.vn</a></p>
            </div>
          </div>
        </div>
        <div className="home__map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.412218258644!2d106.75026385109992!3d10.779706662041388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317525dc0c470f15%3A0xd38c0faad90f24b3!2zMTExIFRyxrDGoW5nIFbEg24gQmFuZywgUGjGsOG7nW5nIFRo4bqhbmggTeG7uSBM4bujaSwgUXXhuq1uIDIsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1652850158411!5m2!1sen!2s" width="100%" height="250"  loading="lazy" ></iframe>
        </div>
      </div>
    </div>
  );
}

export default contactBlock;
